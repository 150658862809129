
import router from '@/router';
// import router from '../../../router';
import { ref } from 'vue'

export default ({
  setup() {
    let contract = localStorage.getItem("Contract");
    const op = ref(null);
    const sharing = {
        url: contract,
        title: 'Ommed S.A',
        description: 'Quero compartilhar meu contrato para ler quando quiser.',
        hashtags: 'ommed,saude'
      };
      const networks = [
        { network: 'email', name: 'E-mail', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
      ]

    const toggle = (event) => {
      op.value.toggle(event);
    }

    const redirect = () => {
      window.location.href = contract;
    }

    const next = () => {
      router.push('/account/login');
    }

    return {
      op, toggle, redirect, contract, sharing, networks, next
    }
  }
})
